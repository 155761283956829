<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <form autocomplete="off" method="post" @submit.prevent="addCustody()">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'employeeToken'"
            :value="custody.employeeToken"
            :options="employeeTokenOptions"
            v-on:changeValue="custody.employeeToken = $event"
            :title="$t('selectEmployee')"
            :imgName="'employees.svg'"
          />

          <CustomSelectBox
            :className="'col-md-6'"
            :id="'treasuryToken'"
            :value="custody.treasuryToken"
            :options="treasuryTokenOptions"
            v-on:changeValue="custody.treasuryToken = $event"
            :title="$t('selectTreasury')"
            :imgName="'treasuries.svg'"
          />
          <CustomInputFloat
            :className="'col-md-6'"
            :id="'custodyValue'"
            :value="custody.custodyValue"
            v-on:changeValue="custody.custodyValue = $event"
            :title="$t('Custodies.value')"
            :imgName="'money.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'custodyDescriptionAr'"
            :value="custody.custodyDescriptionAr"
            v-on:changeValue="custody.custodyDescriptionAr = $event"
            :title="$t('Custodies.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'custodyDescriptionEn'"
            :value="custody.custodyDescriptionEn"
            v-on:changeValue="custody.custodyDescriptionEn = $event"
            :title="$t('Custodies.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'custodyDescriptionUnd'"
            :value="custody.custodyDescriptionUnd"
            v-on:changeValue="custody.custodyDescriptionUnd = $event"
            :title="$t('Custodies.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'custodyNotes'"
            :value="custody.custodyNotes"
            v-on:changeValue="custody.custodyNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'Custodies' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import TextArea from "@/components/general/TextArea.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";
import Treasury from "@/models/finance/treasuries/Treasury";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    CustomSelectBox,
    CustomInputFloat,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeTokenOptions: [],
      treasuryData: new Treasury(),
      treasuryTokenOptions: [],
    };
  },
  props: ["custody", "submitName"],
  methods: {
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfTreasuries() {
      this.isLoading = true;
      try {
        this.treasuryTokenOptions =
          await this.treasuryData.getDialogOfTreasuries(
            this.language,
            this.userAuthorizeToken
          );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    addCustody() {
      this.$emit("addCustody");
    },
  },
  watch: {},
  created() {
    this.getDialogOfEmployees();
    this.getDialogOfTreasuries();
  },
};
</script>
